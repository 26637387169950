<template>
  <div>
    <v-card>
      <v-card-text>
        <v-form
          class="d-flex flex-column justify-center"
          @submit.prevent="createUser"
        >
          <div>
            <v-text-field
              v-model="firstname"
              :label="$t('form.usersManagement.firstname')"
            /><v-text-field
              v-model="lastname"
              :label="$t('form.usersManagement.lastname')"
            />
          </div>
          <v-text-field
            v-model="login"
            :label="$t('form.usersManagement.login')"
            :rules="rules.login"
          />
          <v-text-field
            v-model="password"
            :rules="rules.password"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('form.usersManagement.password')"
            :append-icon="
              showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
            "
            @click:append="showPassword = !showPassword"
          />
          <v-btn type="submit" color="primary" v-text="$t('button.validate')" />
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import debounce from "lodash/debounce";
export default {
  data() {
    return {
      rules: {
        login: [v => !!v || this.$t("rules.fieldNoEmpty")],
        password: [v => !!v || this.$t("rules.fieldNoEmpty")]
      },
      firstname: "",
      lastname: "",
      login: "",
      password: "",
      showPassword: false
    };
  },
  computed: {},
  methods: {
    ...mapActions("user", ["fetchCreateUser", "checkLogin"]),
    async createUser() {
      const canTakeLogin = await this.checkLoginTaken();
      if (canTakeLogin) {
        this.$root
          .$confirm(
            this.$t("form.usersManagement.confirmCreateTitle"),
            this.$t("form.usersManagement.confirmCreate"),
            {
              color: "error"
            }
          )
          .then(confirm => {
            if (confirm) {
              this.fetchCreateUser({
                login: this.login,
                password: this.password,
                firstname: this.firstname,
                lastname: this.lastname
              })
                .then(() => {
                  this.$root.$snackBar(
                    this.$t("success.createUser", { name: this.login })
                  );
                })
                .catch(() => {
                  this.$root.$snackBar(
                    this.$t("errors.createUser", { name: this.login }),
                    {
                      color: "error"
                    }
                  );
                });
            }
          });
      } else {
        this.$root.$snackBar(
          this.$t("errors.loginTaken", { name: this.login }),
          {
            color: "error"
          }
        );
      }
    },
    async checkLoginTaken() {
      const chkLogin = await this.checkLogin({ login: this.login });
      return chkLogin.canTakeIt;
    }
  },
  created() {}
};
</script>
